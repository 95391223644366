<template>
  <div class="container-xl">
    <div :class="s.wrap">
      <div :class="s.side" v-if="flag.showSearchColumn">
        <SearchColumn
          :columns="['brand', 'price', 'mileage']"
          :baseUrl="'/purchase-car/'" />
        <div
          @click="flag.showSearchColumn = !flag.showSearchColumn"
          class="sp bdr rounded-2 bdr-c-red fc-red fw-bold text-center p-2 pointer mt-5">閉じる</div>
      </div>
      <div :class="s.main">
        <!-- キーワード検索 -->
        <form class="mb-4 input-group" @submit.prevent="updateKeyword()">
          <input
            type="text"
            class="form-control"
            placeholder="キーワードで検索"
            v-model="keyword">
          <button
            class="btn"
            :class="s.btn"
            @click="updateKeyword"
            type="button"><i class="fa-solid fa-magnifying-glass me-2"/>検索</button>
        </form>
      <div class="fw-bold fs-lg">買取車種一覧</div>
      <div class="separator mt-3"/>
      <spacer :y="4"/>
      <Loader :flag="flag.isLoading.get">
      <div v-if="!flag.isLoading.get">
        <ul v-if="purchaseCars.length" :class="s.list">
          <li
            v-for="car in purchaseCars"
            :key="car.id">
            <div class="bdr overflow-hidden rounded-2">
              <router-link :to="`/purchase-car/${car.slug}/`">
                <div :class="s.img" :style="`background-image: url(${car.images?.[0]?.url || '/img/noimg.png'});`"/>
                <div class="fc-black p-3">
                  <div class="fw-bold fs-lg">{{ car.vehicle.name }}</div>
                  <div class="fs-xs">{{ car.brand.label }}</div>
                  <div class="mt-2">
                    <div class="fw-bold fs-xs fc-gray">買取相場</div>
                    <div class="fc-red">
                      <span class="fw-bold">{{ car.market_price_min || car.market_price_min === 0 ? `${car.market_price_min?.toLocaleString()}万円` : '未設定' }}</span>
                      〜
                      <span class="fw-bold">{{ car.market_price_max ? `${car.market_price_max?.toLocaleString()}万円` : '未設定' }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </li>
        </ul>
        <div v-else>買取車両の情報はありませんでした。</div>
      </div>
      </Loader>
    </div>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
  SearchColumn,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'purchase-car-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    SearchColumn,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
        showSearchColumn: false,
      },
      purchaseCars: [],
    };
  },
  created() {
    this.keyword = this.$route.query.keyword ? decodeURIComponent(this.$route.query.keyword) : '';
    this.getList();
  },
  mounted() {
    if (window.innerWidth >= 767) this.flag.showSearchColumn = true;
  },
  computed: {
    getParams() {
      const query = this.$route.query;
      const params = {};

      let searchedByPrice = false;
      Object.keys(query).forEach((key) => {
        if (key === 'brand' || key === 'vehicle') params[`${key}Name`] = decodeURIComponent(query[key]);
        else if (key === 'order') this.orderBy = query[key];
        else if (key !== 'keyword') params[key] = decodeURIComponent(query[key]);
        if (key.includes('price')) searchedByPrice = true;
      });

      if (this.keyword) {
        params.keyword = this.keyword.replace(/　/g, ' ').split(' ');
        params.targetColumn = ['brand.name', 'brand.label', 'vehicle.name'];
      }

      if (searchedByPrice) params.priceSearchType = 1;

      return params;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getList();
      },
      immediate: false,
    },
  },
  methods: {
    getList() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        ...this.getParams,
      };

      this.axios({
        method: 'GET',
        url: '/v1/purchaseCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.purchaseCars = res.purchaseCars.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    updateKeyword() {
      const query = {
        ...this.$route.query,
        keyword: encodeURIComponent(this.keyword),
      };
      this.$router.replace({ path: '/purchase-car/', query });
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: flex;
  padding-top: 32px;
  position: relative;
}
.main {
  flex: 1;
  margin-left: 32px;
  @include smView {
    margin-left: 0;
  }
}
.side {
  width: calc(100% / 4);
  max-width: 300px;
  background-color: #ffffff;
  @include smView {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    padding: calc(var(--header-height) + 24px) 24px 0px;
    height: 100vh;
    overflow: scroll;
  }
}

.btn {
  color: #ffffff;
  background-color: var(--primary-red);
  transition: all .3s;
  &:hover {
    color: #ffffff;
    background-color: var(--primary-red);
    opacity: .4;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  $pdg: 8px;
  > li {
    width: calc(100% / 5);
    padding: $pdg;
  }

  @include smView {
    > li {
      width: calc(100% / 2);
    }
  }
  @include xsView {
    > li {
      width: 100%;
    }
  }
}
.img {
  width: 100%;
  padding-top: 75%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--bg-gray);
}
</style>
