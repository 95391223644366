<template>
  <div class="container">
    <Loader :flag="flag.isLoading.get">
      <div v-if="!flag.isLoading.get">
        <div v-if="detail">
          <div class="fw-bold fs-lg">{{ detail.vehicle?.name }}（{{ detail.brand?.label }}）</div>
          <div class="fs-xs mt-2">
            <div class="fw-bold">{{ detail.grade }}</div>
            <div
              class="fc-gray mt-1"
              v-if="detail.grade_comment">{{ detail.grade_comment }}</div>
          </div>
          <div class="separator mt-3"/>
          <spacer :y="6"/>
          <div :class="s.main">
            <div>
              <div :class="s.img" :style="`background-image: url(${detail.images[showImgIdx]?.url})`"/>
              <spacer :y="2"/>
              <ul :class="s.imgs">
                <li v-for="(img, i) in detail.images" :key="img.id">
                  <div
                    @click="showImgIdx = i"
                    class="pointer"
                    :style="`background-image: url(${img.url})`"/>
                </li>
              </ul>
            </div>
            <div>
              <div>
                <div class="pb-2 fw-bold fc-gray">買取相場</div>
                <div class="separator mb-3"/>
                <div
                  class="fc-red text-end"
                  :class="s.price">
                  <span class="fw-bold">{{ detail.market_price_min || detail.market_price_min === 0 ? `${detail.market_price_min?.toLocaleString()}万円` : '未設定' }}</span>
                  〜
                  <span class="fw-bold">{{ detail.market_price_max ? `${detail.market_price_max?.toLocaleString()}万円` : '未設定' }}</span>
                </div>
              </div>
              <div v-if="helper.master.labels" class="text-end fs-xs mt-2">
                <span class="fw-bold">{{ detail.color_name }}</span>
                <span class="fw-bold">（{{ helper.master.labels.cars.colors[detail.color_type].label }}／{{ helper.master.labels.cars.colors[detail.color_type].details[detail.color_detail] }}）</span>
              </div>
              <spacer :y="3"/>
              <ul :class="s.details">
                <li
                  v-for="item in detailItems"
                  :key="item.name">
                  <div class="fs-xs fw-bold fc-gray mb-2">{{ item.label }}</div>
                  <div v-if="item.name === 'regist_year'" class="fs-lg text-end fw-bold">{{ detail.regist_year ? `${detail.regist_year}年` : '-' }}</div>
                  <div v-if="item.name === 'mileage'" class="fs-lg text-end fw-bold">{{ detail.mileage ? `${detail.mileage}km` : '-' }}</div>
                  <div v-if="item.name === 'repair_history'" class="fs-lg text-end fw-bold">{{ detail.repair_history ? 'あり' : 'なし' }}</div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="detail.summary" class="mt-5">
            <div class="pb-2 fw-bold">概要</div>
            <div class="separator mb-3"/>
            <div class="n2br" v-html="detail.summary"/>
          </div>
          <div v-if="detail.comment" class="mt-5">
            <div class="pb-2 fw-bold">詳細</div>
            <div class="separator mb-3"/>
            <div class="n2br" v-html="detail.comment"/>
          </div>
          <!-- <div v-if="detail.options" class="mt-5">
            <div class="pb-2 fw-bold">オプション</div>
            <div class="separator mb-3"/>
            <OptionList :optionValue="detail.options"/>
          </div> -->
        </div>
        <div v-else>
          <NotFound/>
        </div>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  // OptionList,
} from '@/components/parts';
import NotFound from '@/views/404.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'purchase-car-detail',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    // OptionList,
    NotFound,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      detail: null,
      showImgIdx: 0,
      detailItems: [
        {
          name: 'regist_year',
          label: '年式（初度登録）',
        },
        {
          name: 'mileage',
          label: '走行距離目安',
        },
        // {
        //   name: 'repair_history',
        //   label: '修復歴',
        // },
      ],
    };
  },
  created() {
    this.getPurchaseCar();
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    getPurchaseCar() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        slug: this.$route.params.slug,
      };

      this.axios({
        method: 'GET',
        url: '/v1/purchaseCar/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.detail = res.detail;
          if (this.detail) this.setBreadCrumb();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    setBreadCrumb() {
      const brand = this.detail.brand;
      const vehicle = this.detail.vehicle;
      const data = {
        items: [
          {
            label: '買取車両一覧',
            path: '/purchase-car/',
          },
          {
            label: `${vehicle.name}（${brand.label}）`,
            path: `/purchase-car/${this.detail.slug}/`,
          },
        ],
      };
      this.$store.dispatch('breadCrumb/setItem', data);
    },
  },
};
</script>

<style lang="scss" module="s">
.main {
  display: flex;
  > :first-child {
    width: 40%;
  }
  > :last-child {
    flex: 1;
    margin-left: 24px;
  }

  @include smView {
    display: block;
    > :first-child {
      width: 100%;
    }
    > :last-child {
      margin-left: 0;
      margin-top: 36px;
    }
  }
}
.img {
  width: 100%;
  padding-top: calc(100% * ( 2 / 3 ));
  background-position: center;
  background-size: cover;
}

.imgs {
  display: flex;
  flex-wrap: wrap;
  $mgn: 12px;
  margin-right: -$mgn;
  margin-bottom: -$mgn;
  > li {
    width: calc(100% / 8);
    padding: 0 $mgn $mgn 0;
    > * {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}
.price {
  font-size: 32px;
  @include xsView {
    font-size: 24px;
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--bg-gray);
  border-radius: 8px;
  > li {
    width: calc(100% / 2);
    padding: 12px 24px;
    margin: 8px 0;
    &:not(:nth-child(3n + 1)) {
      border-left: 1px solid var(--border-gray);
    }
  }

  @include xsView {
    flex-flow: column;
    align-items: center;
    > li {
      width: calc(100% - 16px);
      &:not(:nth-child(3n + 1)) {
        border-left: none;
      }
      &:not(:first-child) {
        border-top: 1px solid var(--border-gray);
      }
    }
  }
}
</style>
